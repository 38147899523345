import ru from '../trans/ru.json'
import az from '../trans/az.json'
import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'

const resources = {
    az: {
        translation: az,
    },
    ru:{
        translation:ru,
    }
}

i18n
.use(initReactI18next)
.init({
    resources,
    lng: JSON.parse(localStorage.getItem('language')),
    fallbackLng:'ru'
})

export default i18n