import React from 'react'
import styles from './Header.module.scss'
import BurgerNavBar from './burger-navbar/BurgerNavBar'
import NavBar from './navbar/NavBar'
import Langs from './langs/Langs'
import useLocalStorage from './../../hooks/useLocalStorage'
import i18n from './../i18n'

export default function Header() {
    const [language, setLanguage] = useLocalStorage('language', 'ru')

    const handleLanguageChangeAz = () => {
        i18n.changeLanguage('az');
        setLanguage('az');
    };

    const handleLanguageChangeRu = () => {
        i18n.changeLanguage('ru');
        setLanguage('ru');
    };

    return (
        <header className={ styles.header }>
            <div className={ styles.content }>
                <div className={ styles.title }>
                    <img src="/assets/images/logo.svg" alt="" />
                </div>
                <NavBar/>
                <Langs 
                    handleLanguageChangeAz={ handleLanguageChangeAz }
                    handleLanguageChangeRu={ handleLanguageChangeRu }/>
                <div className={ styles["phone-important"] }>
                    <img className={ `${ styles.img } ${ styles.call }` } src="/assets/images/call.svg" alt="" />
                    <img className={ `${ styles.img } ${ styles.phone }` } src="/assets/images/phone.svg" alt="" />
                </div>
            </div>
            <BurgerNavBar/>
        </header>
    )
}
