import React from 'react'
import styles from './Loader.module.scss'

export default function Loader() {
    return (
        <div className={ styles["img-container"] }>
            {/* <img className={ styles.img } src="/assets/images/loader.gif" alt="" /> */}
        </div>
    )
}
