import React from 'react'
import styles from './Langs.module.scss'

export default function Langs({ handleLanguageChangeAz, handleLanguageChangeRu }) {
    return (
        <div className={ styles.langs }>
            <button className={ styles.lang } onClick={ () => handleLanguageChangeAz() }>
                <img src="/assets/images/az.svg" alt="" />
            </button>
            <button className={ styles.lang } onClick={ () => handleLanguageChangeRu() }>
                <img src="/assets/images/ru.svg" alt="" />
            </button>        
        </div>
    )
}
