import React from 'react'
import styles from './About.module.scss'
import { useTranslation } from 'react-i18next'

export default function About() {
    const { t } = useTranslation()
    
    return (
        <div className={ styles.about } id='about'>
            <div className={ styles.content }>
                <h1 className={ styles.title }>{ t('main.about.name') }</h1>
                <p className={ styles.text }>{ t('main.about.intro') }</p>
                <div className={ styles.lists }>
                    <ul className={ styles.list }>
                        <li className={ styles["list-item"] }>{ t('main.about.1-list-item') }</li>
                        <li className={ styles["list-item"] }>{ t('main.about.2-list-item') }</li>
                        <li className={ styles["list-item"] }>{ t('main.about.3-list-item') }</li>
                    </ul>
                    <ul className={ styles.list }>
                        <li className={ styles["list-item"] }>{ t('main.about.4-list-item') }</li>
                        <li className={ styles["list-item"] }>{ t('main.about.5-list-item') }</li>
                        <li className={ styles["list-item"] }>{ t('main.about.6-list-item') }</li>
                    </ul>
                </div>
                <div className={ styles.tandir }>
                    <img className={ styles.img } src="/assets/images/tandir.svg" alt="" />
                </div>
            </div>
        </div>
    )
}
